/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import {
  mergeCraftPreview,
  resolveImage,
  resolveStatBlocks,
} from "@dataResolvers";
import { Data as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query DataQuery {
    craft {
      entry(section: "data") {
        title
        url
        slug
        ... on Craft_data_data_Entry {
          #meta
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          #hero
          heroLabel
          heroTitle
          heroImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          #overview
          commonHeader
          commonDescription
          dataPeopleStats {
            ... on Craft_stats_stats_Entry {
              uid
              statsValue
              statsLabel
              statsIcon {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          dataPeopleCTA {
            text
            url
          }
          #stats
          dataSectionBlocks {
            ... on Craft_dataSectionBlocks_dataImageBgBlock_BlockType {
              typeHandle
              imageBgTitle
              imageBgStats {
                ... on Craft_stats_stats_Entry {
                  uid
                  statsIcon {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                  statsValue
                  statsLabel
                }
              }
              imageBgImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
            ... on Craft_dataSectionBlocks_dataWhiteBgBlock_BlockType {
              typeHandle
              whiteBgTitle
              whiteBgStats {
                ... on Craft_stats_stats_Entry {
                  uid
                  statsIcon {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                  statsValue
                  statsLabel
                }
              }
              whiteBgGraphs {
                ... on Craft_graphs_graphs_Entry {
                  title
                  graphsType
                  graphsDataPoints {
                    ... on Craft_graphsDataPoints_dataPoints_BlockType {
                      xAxisLabel
                      yAxisLabel
                      dataValue
                      dataColor
                    }
                  }
                }
              }
              whiteBgStats {
                ... on Craft_stats_stats_Entry {
                  uid
                  statsValue
                  statsLabel
                  statsIcon {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                }
              }
              whiteBgCTA1 {
                ... on Craft_linkField_Link {
                  text
                  url
                }
              }
              whiteBgCTA2 {
                ... on Craft_linkField_Link {
                  text
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

const previewQuery = `query DataQuery {
  craft {
    entry(section: "data") {
      title
      url
      slug
      ... on Craft_data_data_Entry {
        #meta
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        #hero
        heroLabel
        heroTitle
        heroImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        #overview
        commonHeader
        commonDescription
        dataPeopleStats {
          ... on Craft_stats_stats_Entry {
            uid
            statsValue
            statsLabel
            statsIcon {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
        }
        dataPeopleCTA {
          text
          url
        }
        #stats
        dataSectionBlocks {
          ... on Craft_dataSectionBlocks_dataImageBgBlock_BlockType {
            typeHandle
            imageBgTitle
            imageBgStats {
              ... on Craft_stats_stats_Entry {
                uid
                statsIcon {
                  width
                  height
                  hasFocalPoint
                  slug
                  status
                  title
                  focalPoint
                  url
                }
                statsValue
                statsLabel
              }
            }
            imageBgImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
          ... on Craft_dataSectionBlocks_dataWhiteBgBlock_BlockType {
            typeHandle
            whiteBgTitle
            whiteBgStats {
              ... on Craft_stats_stats_Entry {
                uid
                statsIcon {
                  width
                  height
                  hasFocalPoint
                  slug
                  status
                  title
                  focalPoint
                  url
                }
                statsValue
                statsLabel
              }
            }
            whiteBgGraphs {
              ... on Craft_graphs_graphs_Entry {
                title
                graphsType
                graphsDataPoints {
                  ... on Craft_graphsDataPoints_dataPoints_BlockType {
                    xAxisLabel
                    yAxisLabel
                    dataValue
                    dataColor
                  }
                }
              }
            }
            whiteBgStats {
              ... on Craft_stats_stats_Entry {
                uid
                statsValue
                statsLabel
                statsIcon {
                  width
                  height
                  hasFocalPoint
                  slug
                  status
                  title
                  focalPoint
                  url
                }
              }
            }
            whiteBgCTA1 {
              ... on Craft_linkField_Link {
                text
                url
              }
            }
            whiteBgCTA2 {
              ... on Craft_linkField_Link {
                text
                url
              }
            }
          }
        }
      }
    }
  }
}`;

const dataResolver = ({ craft }) => {
  const {
    title,
    url,
    slug,
    // meta
    metaTitle,
    metaDescription,
    metaImage,
    // hero
    heroLabel,
    heroTitle,
    heroImage,
    // overview
    commonHeader,
    commonDescription,
    dataPeopleStats,
    dataPeopleCTA,
    // stat block
    dataSectionBlocks,
  } = craft.entry;

  const resolveStats = stats =>
    stats.map(s => {
      return {
        id: s.uid,
        icon: resolveImage(s.statsIcon),
        value: s.statsValue,
        label: s.statsLabel,
      };
    });

  return {
    hero: {
      label: heroLabel,
      heading: heroTitle,
      image: resolveImage(heroImage),
    },
    overview: {
      heading: commonHeader,
      copy: commonDescription,
      stats: resolveStats(dataPeopleStats),
      link: {
        text: dataPeopleCTA.text,
        url: dataPeopleCTA.url,
      },
    },
    statBlocks: resolveStatBlocks(dataSectionBlocks),
    meta: {
      title,
      url,
      slug,
      metaTitle,
      metaDescription,
      metaImage: resolveImage(metaImage || heroImage),
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
